interface GetMetaDataArgs {
  title?: string;
  description?: string;
  image?: string;
}

export function getMetadata({
  title = "Soulbound",
  description = "Soulbound is a social gaming platform made sticky. Flex your gamer identity, in-game quests, achievements and stickers!",
  image,
}: GetMetaDataArgs = {}) {
  return [
    {
      title: title,
    },
    {
      property: "og:title",
      content: title,
    },
    {
      property: "og:description",
      content: description,
    },
    {
      property: "og:image",
      content: image,
    },
    {
      property: "twitter:card",
      content: "summary_large_image",
    },
    {
      property: "twitter:site",
      content: "@soulbound_gg",
    },
    {
      property: "twitter:title",
      content: title,
    },
    {
      property: "twitter:description",
      content: description,
    },
    {
      property: "twitter:image",
      content: image,
    },
  ];
}
